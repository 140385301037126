import { ReactElement } from "react";

import {
  useGetIdentity,
  Datagrid,
  TextField,
  useRecordContext,
  ReferenceManyField,
  BulkDeleteButton,
  BulkDeleteButtonProps
} from "react-admin";

import { runeTheme } from "../../common/RuneTheme";

import { RuneDateField } from "../../fields/RuneDateField";
import { PatientDeviceIcons } from "./PatientShow/PatientDeviceIcons";
import { NoResourceMessage } from "../../common/NoResourceMessage";

import { StudyDatagridHeader } from "../../common/StudyDataGrid/StudyDatagridHeader";
import { Org } from "../../../types";
import { useFlags } from "../../../clients/launchDarkly";

const NoPatientMessage = () => (
  <NoResourceMessage>No patients.</NoResourceMessage>
);

const PatientList = () => {
  const { data: user } = useGetIdentity();
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;
  const { patientStartEndDate } = useFlags();

  let bulkActionOption: false | ReactElement<string> | undefined = false;
  const dateFmtOptions = { year: "numeric", month: "short", day: "numeric" };

  const dateSubLabels = patientStartEndDate
    ? [
        { source: "startTime", sortable: true },
        { source: "endTime", sortable: true }
      ]
    : [
        { source: "patientAccessCreatedAt", sortable: true },
        { source: "createdAt", sortable: true }
      ];
  const groupedLabels = [];

  groupedLabels.push(
    { groupName: "", subLabels: [{ source: "codeName", sortable: true }] },
    { groupName: "", subLabels: [{ source: "id", sortable: true }] }
  );
  if (isSuperAdmin) {
    bulkActionOption = <PatientActionButtons />;
    groupedLabels.push(
      {
        groupName: "",
        subLabels: [{ source: "identifiableInfo.realName", sortable: true }]
      },
      {
        groupName: "",
        subLabels: [{ source: "identifiableInfo.email", sortable: true }]
      }
    );
  }
  groupedLabels.push(
    {
      groupName: "Dates",
      subLabels: dateSubLabels
    },
    { groupName: "", subLabels: [{ name: "Devices", sortable: false }] }
  );

  return (
    <ReferenceManyField
      label="Patients"
      reference={"Patient"}
      target={"patient_id"}
    >
      <Datagrid
        rowClick="show"
        empty={<NoPatientMessage />}
        header={<StudyDatagridHeader groupedLabels={groupedLabels} />}
        sx={{
          "& .RaDatagrid-rowCell": {
            minWidth: "120px",
            maxWidth: "400px",
            wordBreak: "break-word"
          }
        }}
        bulkActionButtons={bulkActionOption}
      >
        <TextField source="codeName" label="Code Name" />
        <TextField source="id" label="Patient ID" />
        {isSuperAdmin && (
          <TextField
            source="identifiableInfo.realName"
            label="Strive Username"
            sortable={false}
          />
        )}
        {isSuperAdmin && (
          <TextField
            source="identifiableInfo.email"
            label="Strive Email"
            sortable={false}
          />
        )}

        <RuneDateField
          source={patientStartEndDate ? "startTime" : "patientAccessCreatedAt"}
          label={patientStartEndDate ? "Start" : "Added"}
          showTime={true}
          dateFmtOptions={dateFmtOptions}
        />
        <RuneDateField
          source={patientStartEndDate ? "endTime" : "createdAt"}
          label={patientStartEndDate ? "End" : "Created"}
          showTime={true}
          dateFmtOptions={dateFmtOptions}
        />
        <PatientDeviceIcons label="Devices" />
      </Datagrid>
    </ReferenceManyField>
  );
};

const RuneBulkDeleteButton = (props: BulkDeleteButtonProps) => {
  return (
    <BulkDeleteButton
      sx={{
        fontFamily: "Work Sans",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "22px",
        letterSpacing: "-0.03em",
        textDecorationLine: "underline",
        color: runeTheme.palette.primary.main,
        textTransform: "none",
        alignItems: "center"
      }}
      label="Remove Patients"
      confirmContent="Are you sure you want to remove the selected patient(s) from the org?"
      mutationMode="pessimistic"
      {...props}
    />
  );
};

const PatientActionButtons = () => {
  const org = useRecordContext<Org>();
  return (
    <>
      (
      <>
        <RuneBulkDeleteButton
          confirmTitle={`Remove patient(s) from "${org.displayName}" org`}
          mutationOptions={{ meta: { orgId: org.id } }}
        />
      </>
      )
    </>
  );
};

export default PatientList;
