import React, { useState, useEffect } from "react";
import {
  Button,
  Edit,
  SimpleForm,
  SelectInput,
  DateInput,
  TextInput,
  required,
  useDataProvider,
  useRecordContext,
  useRefresh,
  useNotify,
  useUpdate,
  Toolbar,
  useGetIdentity
} from "react-admin";

import { styled } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { runeTheme } from "../../common/RuneTheme";
import iconCheckMark from "../../../shared/icon/checkmark.svg";

import RuneSaveButton from "../../common/buttons/RuneSaveButton";
import SecondaryActionButton from "../../common/buttons/SecondaryActionButton";

import iconEdit from "../../../shared/icon/edit.svg";
import { useFlags } from "../../../clients/launchDarkly";

const Wrapper = styled("div")({
  boxShadow: "none"
});

interface AppMode {
  id: number;
  name: string;
}

function PatientUpdateToolbar(props: { handleClickClose: () => void }) {
  return (
    <Toolbar
      sx={{
        background: runeTheme.palette.background.light,
        display: "flex",
        justifyContent: "flex-end"
      }}
      disableGutters={true}
    >
      <SecondaryActionButton onClick={props.handleClickClose} label="Cancel" />
      <RuneSaveButton label="Save" icon={iconCheckMark} />
    </Toolbar>
  );
}

function PatientUpdateForm(props: {
  patientId: string;
  handleClickClose: () => void;
}) {
  const { handleClickClose, patientId } = props;
  const refresh = useRefresh();
  const notify = useNotify();

  const [update] = useUpdate();
  const dataProvider = useDataProvider();
  const [appModes, setAppModes] = useState<AppMode[]>([]);
  const record = useRecordContext();
  const { data: user } = useGetIdentity();
  const { patientStartEndDate } = useFlags();
  const isSuperAdmin = user?.defaultMembership?.role.superAdmin;

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const save = (data: any) => {
    data.patientId = patientId;
    // parse react admin's date string, which can be returned as a string like "2024-01-01"
    // or a js timestamp in milliseconds depending on if the form updates data.
    // also convert from JS timestamp (ms) to unix timestamp (s)
    if (data.startTimeUpdate && typeof data.startTimeUpdate !== "number") {
      const [startYear, startMonth, startDay] = data.startTimeUpdate
        .split("-")
        .map(Number);
      const start = Date.UTC(startYear, startMonth - 1, startDay, 12);
      data.startTimeUpdate = Math.floor(start / 1000);
    } else {
      data.startTimeUpdate =
        data.startTimeUpdate > 0
          ? Math.floor(data.startTimeUpdate / 1000)
          : null;
    }
    if (data.endTimeUpdate && typeof data.endTimeUpdate !== "number") {
      const [endYear, endMonth, endDay] = data.endTimeUpdate
        .split("-")
        .map(Number);
      const end = Date.UTC(endYear, endMonth - 1, endDay, 12);
      data.endTimeUpdate = Math.floor(end / 1000);
    } else {
      data.endTimeUpdate =
        data.endTimeUpdate > 0 ? Math.floor(data.endTimeUpdate / 1000) : null;
    }
    data.orgId = user?.defaultMembership?.org?.id;
    update(
      "Patient",
      { data },
      {
        mutationMode: "pessimistic",
        onSuccess(data) {
          if (data?.errors?.length > 0) {
            notify(`Error: ${data.errors[0].message}`, {
              type: "error"
            });
            return;
          }
          refresh();
          notify("Patient details successfully updated.", {
            type: "info"
          });
        },
        onError(error) {
          if (error instanceof Error) {
            notify(error.message, {
              type: "error"
            });
          } else {
            notify(
              "Unknown error occurred. Unable to update patient details.",
              {
                type: "error"
              }
            );
          }
        }
      }
    );
  };

  useEffect(() => {
    dataProvider
      .getMany("AppModes", { ids: [] })
      .then((response) => {
        const appModesArray = response.data.map((appMode) => {
          return {
            id: appMode.id,
            name: appMode.displayName
          };
        });
        setAppModes(appModesArray);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dataProvider]);

  if (appModes.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <SimpleForm
      sx={{ maxWidth: 570 }}
      onSubmit={save}
      toolbar={PatientUpdateToolbar({ handleClickClose })}
    >
      <TextInput
        variant="outlined"
        source="codeName"
        id="codeName"
        label="Code Name"
        fullWidth
        validate={required()}
      />
      <SelectInput
        variant="outlined"
        source="appModeId"
        label="App Mode"
        fullWidth
        choices={appModes}
        isRequired
        defaultValue={record.appMode?.id}
        validate={required()}
      />
      {patientStartEndDate && isSuperAdmin && (
        <DateInput
          variant="outlined"
          source="startTimeUpdate"
          id="startTime"
          label="Start Date"
          fullWidth
          defaultValue={record.startTime ? record.startTime * 1000 : null}
        />
      )}
      {patientStartEndDate && isSuperAdmin && (
        <DateInput
          variant="outlined"
          source="endTimeUpdate"
          id="endTime"
          label="End Date"
          fullWidth
          defaultValue={record.endTime ? record.endTime * 1000 : null}
        />
      )}
    </SimpleForm>
  );
}

export default function PatientUpdateDialog(props: { patientId: string }) {
  const [open, setOpen] = useState(false);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const handleClickOpen = (e: any) => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{
          display: "flex",
          padding: "16px",
          gap: "4px",
          height: "19px",
          fontSize: "16px",
          lineHeight: "19px",
          letterSpacing: "-0.03em",
          textTransform: "capitalize",
          fontWeight: "600",
          color: runeTheme.palette.button.tertiary.text,
          margin: "8px"
        }}
        onClick={handleClickOpen}
        label={"Edit Patient"}
        startIcon={<img src={iconEdit} alt="Edit" />}
      />
      <Dialog open={open} onClose={handleClickClose}>
        <DialogTitle>Update Patient Details</DialogTitle>
        <DialogContent>
          <PatientUpdateForm
            handleClickClose={handleClickClose}
            patientId={props.patientId}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
