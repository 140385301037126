import { Box } from "@mui/material";
import { get } from "lodash";
import { useState } from "react";
import { useRecordContext } from "react-admin";
import moment from "moment-timezone";
import DataAvailabilitySingleDatePicker from "src/components/common/DataAvailabilitySingleDatePicker/DataAvailabilitySingleDatePicker";
import { PatientDailyTremorBarChart } from "./PatientDailyTremorBarChart";
import { PatientDailyDyskinesiaBarChart } from "./PatientDailyDyskinesiaBarChart";
import { useLocation } from "react-router-dom";

export const PatientDataAvailability = () => {
  const record = useRecordContext();
  const patientId = get(record, "id");
  const location = useLocation();

  if (!patientId) return null;

  const queryParams = new URLSearchParams(location.search);
  const dateFromUrl = queryParams.get("date");

  const [dataAvailabilityDate, setDataAvailabilityDate] =
    useState<moment.Moment | null>(
      dateFromUrl ? moment(dateFromUrl) : moment()
    );
  const [datePickerFocused, setDatePickerFocused] = useState<boolean>(false);

  return (
    <Box>
      <div
        style={{
          background: "#DBEEFB",
          borderRadius: "24px 24px 0px 0px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingLeft: "24px",
            paddingTop: "12px",
            paddingBottom: "12px"
          }}
        >
          <DataAvailabilitySingleDatePicker
            id="dataAvailabilityDatePicker"
            patientId={patientId.toString()}
            date={dataAvailabilityDate}
            focused={datePickerFocused}
            setDate={setDataAvailabilityDate}
            setFocus={setDatePickerFocused}
          />
        </div>
      </div>
      <PatientDailyTremorBarChart
        date={dataAvailabilityDate?.toDate()}
        patientId={patientId.toString()}
      />

      <PatientDailyDyskinesiaBarChart
        date={dataAvailabilityDate?.toDate()}
        patientId={patientId.toString()}
      />
    </Box>
  );
};
